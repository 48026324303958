<template>
  <div class="wh-container">
    <header class="header no-print">
      <p class="title">Configurações do centro cirúrgico</p>
    </header>

    <v-tab
      ref="surgery_center_tabs"
      :index="currentTabIndex"
      :keys="tabs"
      @onChange="onChangeTab"
    >
      <v-general-sheets class="tab" v-if="currentTabIndex === 0" />
      <v-admission-form class="tab" v-else-if="currentTabIndex === 1" />
      <v-patient-preparation class="tab" v-else-if="currentTabIndex === 2" />
      <v-surgical-note
        class="tab"
        v-else-if="currentTabIndex === 3"
        :headerTitle="title"
      />
    </v-tab>
  </div>
</template>

<script>
import { userHasPermission, getCurrentUser } from '@/utils/localStorageManager'
import { parseUserTypeToShow } from '@/utils/surgeryCenterHelper'

export default {
  name: 'SurgeryCenterConfiguration',
  metaInfo: {
    title: 'Eyecare - Configuração do centro cirúrgico'
  },
  components: {
    'v-tab': () => import('@/components/Tab'),
    'v-general-sheets': () => import('@/layouts/SurgeryCenter/Configuration/GeneralSheets'),
    'v-admission-form': () => import('@/layouts/SurgeryCenter/Configuration/AdmissionForm'),
    'v-patient-preparation': () => import('@/layouts/SurgeryCenter/Configuration/PatientPreparation'),
    'v-surgical-note': () => import('@/layouts/SurgeryCenter/Configuration/SurgicalNote')
  },
  data: () => ({
    user: getCurrentUser(),
    currentTabIndex: 0
  }),
  computed: {
    tabs() {
      return [
        'Fichas Gerais',
        'Ficha de admissão',
        'Preparação do paciente',
        `Modelos de ${this.title}`
      ]
    },
    title() {
      return parseUserTypeToShow(this.user?.type)
    }
  },
  created() {
    this.handleTabIndex()
  },
  methods: {
    userHasPermission,
    onChangeTab(index, dontPush) {
      this.currentTabIndex = index
      if (!dontPush) {
        window.history.pushState(
          null,
          null,
          `/configuracoes-do-centro-cirurgico/${this.getPathByIndex(index)}`
        )
      }
    },
    handleTabIndex() {
      const tabs = {
        'fichas-gerais': 0,
        'ficha-de-admissao': 1,
        'preparacao-do-paciente': 2,
        'modelos-de-anotacao': 3,
        default: 0
      }
      this.onChangeTab(tabs[this.$route.params.tab] || tabs.default, false)
    },
    getPathByIndex(index) {
      const indexes = {
        0: 'fichas-gerais',
        1: 'ficha-de-admissao',
        2: 'preparacao-do-paciente',
        3: 'modelos-de-anotacao'
      }
      return indexes[index] || 'fichas-gerais'
    }
  }
}
</script>

<style lang="scss" scoped>
.wh-container {
  height: 100%;
  padding: 30px 24px;

  .tab {
    min-height: 70vh;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: min(2vw, 24px);
    margin-bottom: 24px;
  }
  .title {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: min(2vw, 24px);
    color: var(--dark-blue);
  }
}
</style>
